import { defineStore } from 'pinia'
import {reactive} from "vue";
import {pad} from "@/modules/main";

export const configStore = defineStore('config', {
    state: () => ({
        projectURL: 'https://dashboard.nachtaktivevents.app/',
        projectURLRaw: 'dashboard.nachtaktivevents.app',
        projectURLApp: 'https://nachtaktivevents.app/',
        socketURL: 'https://push.nachtaktivevents.app/live',
        eventMediaUploadPath: 'assets/images/events/',
        darkMode: 0,
        volume: 0,
        vibrate: 0,
        chatModal: false,
        sidebar: false,
        now: Date.now(),
        menuModal: false,
        platform: process.env.CORDOVA_PLATFORM ? process.env.CORDOVA_PLATFORM.toLowerCase() : 'web',
        cordova: !!process.env.CORDOVA_PLATFORM,
        onlineStatus: 15*60*1000,
        organizer: [],
        organizerId: reactive(0),
        organizerCredits: {}
    }),
    getters: {
        timer() {
            return this.now
        },
        calculateTimeString () {
            return (time) => {
                let remainTime = Math.floor((this.now - time) / 1000)
                const remainTimeSec = remainTime
                let timeString = remainTime + 'Sekunden'
                if (remainTime <= 10) timeString = 'Jetzt'
                if (remainTime > 59) {
                    if(remainTime < 120) {
                        timeString = remainTime + ' Minute'
                    }else{
                        remainTime = Math.floor(remainTime / 60)
                        timeString = remainTime + ' Minuten'
                        if (remainTime > 59) {
                            remainTime = Math.floor(remainTime / 60)
                            timeString = remainTime + ' Stunden'
                            if (remainTime > 23) {
                                remainTime = Math.floor(remainTime / 24)
                                timeString = remainTime + ' Tage'
                                if (remainTime === 1)timeString = remainTime + ' Tag'
                                if (remainTime > 6) {
                                    const date = new Date(time)
                                    timeString = 'am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                                }
                            }
                        }
                    }
                }
                let isoString = timeString
                if(remainTimeSec < 60*60*24){
                    if(remainTimeSec < 60*60){
                        isoString = Math.floor(remainTimeSec/60) + ':' + pad(remainTimeSec % 60, 2)
                    }else{
                        isoString = Math.floor(remainTimeSec/3600) + ':' + pad(Math.floor(remainTimeSec % 3600 / 60), 2)  + ':' + pad(remainTimeSec % 60, 2)
                    }
                }

                return {string: timeString, seconds: remainTimeSec, isoString: isoString}
            }
        },
        getCurrentOrganizer(){
            const _this = this
            try {
                return this.organizer.filter(o => {
                    return o.id == _this.organizerId
                })[0]
            }catch (e) {
                return null
            }
        },
        getOrganizerCredits(){
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.organizerCredits[this.organizerId] / 100)
        }
    }
})